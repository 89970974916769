<template>
  <section id="examination">

    <h1 id="page-title">{{ $t('examination') }}</h1>

    <div class="spacecard">
      <div class="tabs-navigator">
        <a :class="{ active: view === v.id }"
           v-for="v in views"
           :key="`view-${v.id}`"
           @click="view = v.id">
          {{ $t(v.text) }}
        </a>
      </div>

      <div class="search-input-wrapper mt-4 mb-3">
        <SearchSvg class="position-absolute search-icon pointer" @click="search"/>
        <input class="search-form-control"
               @keyup.enter="search"
               :placeholder="$t('full_student_search')"
               v-model="q"/>
      </div>

      <div v-if="loaded">
        <StudentGeneralTaskTeaser v-for="(task, tind) in paginationData.data"
                                  :task="task"
                                  :key="`key-${tind}`"/>
        <Paginator :paginator="paginationData" ref="pag"/>
      </div>

    </div>
  </section>
</template>

<script>
import { StudentsService } from "../../../../services/api.service"
import StudentGeneralTaskTeaser from "../../../parts/instructor/StudentGeneralTaskTeaser.vue"
import Paginator from "../../../parts/general/Paginator.vue"
import SearchSvg from '@/assets/svg/search.svg'

export default {
  name: "Examination",
  components: {
    Paginator,
    StudentGeneralTaskTeaser,
    SearchSvg
  },
  metaInfo() {
    return {
      title: this.$t('examination'),
    }
  },
  data() {
    return {
      view: 'all',
      views: [
        { id: 'all', text: 'all' },
        { id: 'not_finished', text: 'not_finished' },
        { id: 'completed', text: 'completed_adj' },
        { id: 'not_reviewed', text: 'not_reviewed' },
      ],
      page: this.$route.query.page ? this.$route.query.page : 1,
      paginationData: {},
      loaded: false,
      q: ''
    }
  },
  methods: {
    async loadData() {
      this.loaded = false
      try {
        const res = await StudentsService.examinationUniversal(this.view, this.page, this.q)
        this.paginationData = res.data.data
      } finally {
        this.loaded = true
      }
    },
    search() {
      this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    view() {
      if(this.page != 1) {
        this.$router.replace({
          query: { page: 1 }
        })
      } else {
        this.loadData()
      }
    },
    $route() {
      if(this.$route.query.page !== this.page) {
        this.page = this.$route.query.page
        this.loadData()
      }
    },
  }
}
</script>

<style scoped>

</style>
