<template>
  <div class="border-bottom py-4 student-task-teaser">
    <b-row class="align-items-center">
      <b-col md="4">
        <b-row>
          <b-col cols="auto">
            <LetterIcon :obj="task"
                        :title="task.student_name"
                        class="student-icon md"/>
          </b-col>
          <b-col>
            <div class="font-weight-bold">
              <router-link :to="{ name: 'StudentHistory', params: { id: task.student_id } }">
                {{ task.student_name }}
              </router-link>
            </div>
            <div class="badge"
                 :class="{ 'badge-themed-purple': typeName === 'mediumcourse', 'badge-themed-warning': typeName === 'homework' }">
              <HomeSvg class="svg-white svg-icon-xs" v-if="typeName === 'homework'"/>
              <StudentSvg class="svg-white svg-icon-sm" v-if="typeName === 'mediumcourse'"/>
              {{ $t(typeName) }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2">
        <div class="font-weight-bold">{{ task.material_title }}</div>
        <div v-if="task.datetime">{{ task.datetime | momentTz('DD.MM.YYYY, HH:mm') }}</div>
      </b-col>
      <b-col md="2">
        <div class="badge"
             :class="{ 'badge-themed-error': !task.finished, 'badge-themed-success': task.finished }">
          {{ $t(doneStatus) }}
        </div>
        <div v-if="task.finished_at">
          {{ task.finished_at | momentTz('DD.MM.YYYY, HH:mm') }}
        </div>
      </b-col>
      <b-col md="2">
        <div class="badge"
             :class="{ 'badge-themed-error': !task.reviewed, 'badge-themed-success-light': task.reviewed }">
          {{ $t(reviewStatus) }}
        </div>
      </b-col>
      <b-col md="2">
        <div>
          <b-button class="btn-themed btn-white" @click="openLesson">
            {{ $t(action) }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LetterIcon from "../general/LetterIcon.vue"
import HomeSvg from '@/assets/svg/home.svg'
import StudentSvg from '@/assets/svg/student2.svg'

export default {
  name: "StudentGeneralTaskTeaser",
  components: {
    LetterIcon,
    HomeSvg,
    StudentSvg
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  methods: {
    openLesson() {
      this.$router.push(this.openUrl)
    }
  },
  computed: {
    doneStatus() {
      return this.task.finished ? 'done' : 'not_done'
    },
    reviewStatus() {
      return this.task.reviewed ? 'checked' : 'not_checked'
    },
    typeName() {
      return this.task.record_type === 'selfstudy' ? 'mediumcourse' : 'homework'
    },
    action() {
      return this.task.finished && !this.task.reviewed ? 'check' : 'open'
    },
    openUrl() {
      if(this.typeName === 'mediumcourse') {
        return {
          name: 'SelfStudyLesson',
          params: {
            id: this.task.material_id,
            studentid: this.task.student_id
          }
        }
      }
      if(this.typeName === 'homework') {
        return {
          name: 'Lesson',
          params: {
            id: this.task.id,
            code: this.task.code,
            homework: 'homework',
            studentid: this.task.student_id
          }
        }
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.student-task-teaser {
  font-size: 14px;
}
</style>
